import React from "react"
import { graphql, Link  } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "../components/Elements/Img"
import SectionContainer from "../components/SectionContainer"
import VideoPlayer from "../components/VideoPlayer/index"
import { Container, Row, Col } from "../components/Grid"
import {
  SectionBody,
  AccentHeader,
  PageTitle,
} from "../components/Elements/index"

const WorkLink = styled(props => <Link {...props} />)`
  color: #414141;
  &:active {
    color: #414141;
  }

  &:visited {
    color: #414141;
  }
`

const RevomeMarginWrapper = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const Footer = styled.footer`
  background: #6a115a;
  display: flex;
  height: 120px;
  align-items: center;
`

const RevomeMarginImage = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const RevomeMarginVideo = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const VideoContainerFrame = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    margin: 0;
    width: 100%;
  }
  iframe {
    position: absolute;
    z-index: 999;
    bottom: 3%;
    width: 53% !important;
    height: 100% !important;
    margin-left: -1%;
  }
`

const pluvictoConsumerDigital = ({ data }) => (
  <Layout>
    <ThemeProvider
      theme={{
        backgroundColor: "#FFFFFF",
        primaryColor: "#414141",
        dividerColor: "#1D4289;",
        invert: {
          backgroundColor: "#1D4289",
          dividerColor: "#ffffff;",
        },
      }}
    >
      <>
        <Seo title="PLUVICTO | PLUVICTO Consumer Digital" />
        <SectionContainer noBottomMargin>
          <Container>
            <AccentHeader size="large" background="#ffffff">
              <WorkLink to="/">
                  <div style={{ color: "#1D4289"}}>The Work</div>
                </WorkLink>
            </AccentHeader>
            <div style={{ paddingBottom: "30px", color: "#1D4289" }}>
              <PageTitle>A simple approach to a complex targeted therapy</PageTitle>
            </div>
            <Row>
              <Col md={4}>                
                <AccentHeader>
                  <div style={{ color: "#1D4289"}}>
                    The Problem
                  </div>
                </AccentHeader>                
                <SectionBody size="regular">
                  Imagine having metastatic prostate cancer. You've endured several
different treatments, some more than once. They've affected your
daily life, but other options are limited—and like many patients, you're
not eligible for the few targeted therapies available. All of that changed
with the launch of PLUVICTO, the first and only PSMA-targeted
radioligand therapy proven to help patients with PSMA+ mCRPC live
longer. The problem became making this new diagnostic and
therapeutic approach understandable for patients and caregivers.<br />
                  <br />                  
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="regular">
                  <h1 style={{ color: "#1D4289"}}>Making high-level science make sense to patients</h1>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>
        

        <SectionContainer invert noBottomMargin noTopMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "30px", color: "#ffffff" }}>
            <Row>
              <Col md={4}>                
                <AccentHeader>The Idea</AccentHeader>                
                <SectionBody size="regular">
                  Create a simple, relatable visual and messaging approach to convey the
key differentiator of PLUVICTO—the ability to target PSMA+
metastases at the cellular level, no matter where they are located in the
body. The solution was to add a twist to a well-known scenario.<br />
                  <br />                  
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="regular">
                  <h1>One target. Many locations. One treatment.</h1>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>
        

        <SectionContainer noBottomMargin noTopMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px" }}>
              <RevomeMarginWrapper noTopMargin noBottomMargin>
                <VideoPlayer
                  poster={() => <Img fluid={data.videoThumbnail1.childImageSharp.fluid} />}
                  url="https://player.vimeo.com/video/792673495?h=916334d21f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                />
              </RevomeMarginWrapper>
          </Container>
        </SectionContainer>


        <SectionContainer invert noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", color: "#ffffff" }}>
            <Row>
              <Col md={8}>
                <AccentHeader>                  
                  <h2 style={{ color: "#ffffff" }}>The Execution</h2>
                </AccentHeader>
                <SectionBody size="regular">
                  Custom photography depicting one dart becoming many along with patient-friendly messaging was used consistently across channels including a patient website, social media, banners, and print. Even the video created to help patients know what to expect during treatment with PLUVICTO reflected this simple, reassuring approach.
                </SectionBody>
              </Col>              
            </Row>
            <SectionContainer noTopMargin noBottomMargin>
              <RevomeMarginImage>
                <Img fluid={data.image2.childImageSharp.fluid} />
              </RevomeMarginImage>
            </SectionContainer>
          </Container>
        </SectionContainer>  


        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", color: "#414141" }}>
            <Row>
              <Col md={8}>
                <h2 style={{ color: "#1D4289" }}>A Seamless Content Ecosystem</h2>
                <SectionBody size="regular">
                  No matter where patients and caregivers encountered PLUVICTO, we created a thoughtful, impactful, and consistent experience. From
awareness through conversion, each step was seamlessly orchestrated—ensuring that patients not only understood the exciting science behind
the first and only treatment of its kind, but also felt empowered to talk to their oncologist about getting a PSMA PET scan to see if PLUVICTO
could be an option for them.
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>  

        <SectionContainer noTopMargin>
            <Container>
              <RevomeMarginImage>
                <Img fluid={data.image3.childImageSharp.fluid} />
              </RevomeMarginImage>
            </Container>
        </SectionContainer>
         

        <SectionContainer invert noTopMargin>
          <Container style={{ backgroundColor: "#1D4289", color: "#ffffff", paddingTop: "60px", paddingBottom: "60px" }}>
            <Row>
              <Col md={8}>
                <AccentHeader>                  
                  <h2 style={{ color: "#ffffff" }}>The Results</h2>
                </AccentHeader>
                <SectionBody size="regular">
                  The PLUVICTO campaign delivered a strong start with 5 million impressions at launch and continues to successfully drive brand awareness as additional banners, social media posts, and website updates are rolled out. Simple. Consistent. Effective. Nothing is more important to making complex, intimidating science a clear and hopeful message for patients with PSMA+ mCRPC.
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>        

        <SectionContainer  noTopMargin noBottomMargin>
          <Container>
            <Row>
              <Col md={8}>
                <hr/>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingBottom: "60px" }}>
            <Row>
              <Col md={8}>                
                <SectionBody size="small">
                  mCRPC, metastatic castration-resistant prostate cancer; PET, positron emission tomography; PSMA, prostate-specific membrane antigen; PSMA+, PSMA positive.
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>


      </>
    </ThemeProvider>
  </Layout>
)

export default pluvictoConsumerDigital

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "2023/pluvicto3/image1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image2: file(relativePath: { eq: "2023/pluvicto3/image2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image3: file(relativePath: { eq: "2023/pluvicto3/image3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    videoThumbnail1: file(relativePath: { eq: "2023/pluvicto3/thumbnail1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
